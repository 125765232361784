import { graphql, navigate } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React, { useMemo, useState, useRef } from "react";
import Moment from "react-moment";
import Layout from "../../../components/Layout";
import Pagination from "../../../components/Pagination/Pagination";
import StandardBanner from "../../../components/StandardHero/StandardHero";

import {
  pressReleaseBannerContainer,
  pressReleaseBannerContent,
  pressReleaseContentContainer,
  allpressReleaseContainer,
  pressReleaseContent,
  paginationContainer,
} from "./index.module.scss";
const PageSize = 16;
const PressRelease = ({
  data: {
    strapiPressRelease,
    strapiPressRelease: { subBanner, metaData },
    allStrapiPressRelease: { nodes: allpressRelease },
  },
}) => {
  const bannerImage =
    strapiPressRelease?.heroBanner?.image?.localFile?.childImageSharp
      ?.gatsbyImageData;
  const bannerTitle = "";
  const showBreadcrumb = strapiPressRelease?.heroBanner?.showBreadcrumb;

  const scrollToRef = useRef();

  const breadcrumbs = [{ name: "PRESS RELEASES" }];

  const [currentPage, setCurrentPage] = useState(1);

  const currentPressReleases = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return allpressRelease.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, allpressRelease]);

  // select only articles with publish date is earlier than now
  const filteredPressReleases = currentPressReleases.filter(function (obj) {
    return new Date(obj.publish_at) < Date.now();
  });

  const handleOnPageChange = (page) => {
    setCurrentPage(page);
    window.scrollTo({
      behavior: "smooth",
      top: scrollToRef.current.offsetTop,
    });
  };

  return (
    <Layout
      seo={{
        title: metaData?.pageTitle,
        description: metaData?.metadescription,
        image: strapiPressRelease.heroBanner.image.localFile.publicURL,
        article: false,
      }}
    >
      <StandardBanner
        image={bannerImage}
        title={bannerTitle}
        showBreadcrumb={showBreadcrumb}
        breadcrumbs={breadcrumbs}
      />
      <section className={pressReleaseBannerContainer}>
        <img src={subBanner.image.localFile.publicURL} alt="logo" />
        <div className={pressReleaseBannerContent}>
          <h3>Press releases</h3>
        </div>
      </section>

      <section className={pressReleaseContentContainer} ref={scrollToRef}>
        <div className={allpressReleaseContainer}>
          {filteredPressReleases.map(
            ({ shortDesc, image, slug, publish_at, id }, index) => {
              return (
                <div
                  role="button"
                  className={pressReleaseContent}
                  key={id}
                  onClick={() => navigate(`/press-release/${slug}`)}
                  onKeyDown={(event) => {
                    if (event.keycode === 13)
                      navigate(`/press-release/${slug}`);
                  }}
                  tabIndex={index}
                >
                  <GatsbyImage
                    image={
                      image?.image?.localFile?.childImageSharp?.gatsbyImageData
                    }
                    alt={image?.image?.alternativeText}
                  />
                  <h4>{shortDesc}</h4>
                  <p className="ui">
                    <Moment format="D MMM YYYY">{publish_at}</Moment>
                  </p>
                  {/* Temporarily commented out.  to be added later
                  <div className={pressReleaseTagsContainer}>
                    <p className="ui">retirement</p>
                    <p className="ui">dws</p>
                  </div> */}
                </div>
              );
            }
          )}
        </div>
        <div className={paginationContainer}>
          <Pagination
            currentPage={currentPage}
            totalCount={filteredPressReleases.length}
            pageSize={PageSize}
            onPageChange={(page) => handleOnPageChange(page)}
          />
        </div>
      </section>
    </Layout>
  );
};

export default PressRelease;

export const query = graphql`
  {
    strapiPressRelease: strapiLatestInsights {
      heroBanner {
        title
        showBreadcrumb
        image {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
        }
      }
      subBanner {
        heading
        image {
          localFile {
            publicURL
          }
          alternativeText
        }
      }
      metaData {
        metadescription
        pageTitle
      }
    }
    allStrapiPressRelease(sort: { order: DESC, fields: publish_at }) {
      nodes {
        id
        publish_at
        publish_at
        shortDesc
        slug
        subtitle
        media_type {
          tyoe
        }
        image {
          image {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  height: 420
                  width: 645
                  placeholder: DOMINANT_COLOR
                )
              }
            }
          }
        }
      }
    }
  }
`;
