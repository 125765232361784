import React from "react";
import { BgImage } from "gbimage-bridge";
import Breadcrumb from "../Breadcrumb/Breadcrumb";

const styles = require("./StandardHero.module.css");

const StandardHero = (props) => {
  const { image, title, showBreadcrumb, breadcrumbs } = props;

  return (
    <BgImage image={image} Tag="section" className={styles.banner}>
      <div className={styles.bannerContent}>
        {showBreadcrumb ? <Breadcrumb breadcrumbs={breadcrumbs} /> : null}
        <h1 className={styles.heading}>{title}</h1>
      </div>
    </BgImage>
  );
};

export default StandardHero;
