import React from "react";
import {
  paginationContainer,
  paginationItem,
  dots,
  selected,
  arrow,
  left,
  right,
  disabled,
} from "./Pagination.module.scss";
import { usePagination, DOTS } from "../../hooks/usePagination";
const Pagination = (props) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  // If there are less than 2 times in pagination range we shall not render the component
  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];
  return (
    <ul className={`${paginationContainer} ${className}`}>
      {/* Left navigation arrow */}
      <li
        className={`${paginationItem} ${currentPage === 1 && disabled}`}
        onClick={onPrevious}
        onKeyDown={(event) => {
          if (event.keycode === 13) onPrevious();
        }}
      >
        <div className={`${arrow} ${left}`} />
      </li>
      {paginationRange.map((pageNumber) => {
        // If the pageItem is a DOT, render the DOTS unicode character
        if (pageNumber === DOTS) {
          return <li className={`${paginationItem} ${dots}`}>&#8230;</li>;
        }

        // Render our Page Pills
        return (
          <li
            className={`${paginationItem} ${
              pageNumber === currentPage && selected
            }`}
            onClick={() => onPageChange(pageNumber)}
            onKeyDown={(event) => {
              if (event.keycode === 13) onPageChange(pageNumber);
            }}
          >
            <p>{pageNumber}</p>
          </li>
        );
      })}
      {/*  Right Navigation arrow */}
      <li
        className={`${paginationItem} ${currentPage === lastPage && disabled}`}
        onClick={onNext}
        onKeyDown={(event) => {
          if (event.keycode === 13) onNext();
        }}
      >
        <div className={`${arrow} ${right}`} />
      </li>
    </ul>
  );
};

export default Pagination;
