import React from "react";
const styles = require("./Breadcrumb.module.scss");

const Breadcrumb = (props) => {
  const { breadcrumbs = "" } = props;

  return (
    <ul className={styles.breadcrumbs}>
      {breadcrumbs.map((breadcrumb, index) => {
        const crumbName = breadcrumbs[0].name ? breadcrumb.name : breadcrumb;

        return crumbName ? (
          <li key={`breadcrumb-${index}`}>
            <span>{crumbName}</span>
          </li>
        ) : null;
      })}
    </ul>
  );
};

export default Breadcrumb;
